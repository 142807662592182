import React, { Fragment } from 'react';
import Footer from './Footer';
import Menu from './Menu';

function Index() {
    return (
        <Fragment>
            <div id="page">
                <Menu />
                <div id="body" className="home">
                    <div className="header">
                        <div>
                            <img src="assets/images/satellite.png" alt="" className="satellite" />
                            <h1>A K H I L </h1>
                            <h2>K U M A R</h2>
                            <a href="blog.html" className="more">Read More</a>
                            <h3>FEATURED PROJECTS</h3>
                            <ul>
                                <li>
                                    <a href="projects.html"><img src="assets/images/project-image1.jpg" alt="" /></a>
                                </li>
                                <li>
                                    <a href="projects.html"><img src="assets/images/project-image2.jpg" alt="" /></a>
                                </li>
                                <li>
                                    <a href="projects.html"><img src="assets/images/project-image3.jpg" alt="" /></a>
                                </li>
                                <li>
                                    <a href="projects.html"><img src="assets/images/project-image4.jpg" alt="" /></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="body">
                        <div>
                            <h1>OUR MISSION</h1>
                            <p>This website template has been designed by Free Website Templates for you, for free. You can replace all this text with your own text.</p>
                        </div>
                    </div>
                    <div className="footer">
                        <div>
                            <ul>
                                <li>
                                    <h1>FEATURED VIDEO</h1>
                                    <a href="/blog"><img src="assets/images/mars-rover.jpg" alt="" /><span></span></a>
                                </li>
                                <li>
                                    <h1>LATEST BLOG</h1>
                                    <ul>
                                        <li>
                                            <a href="blog.html"><img src="assets/images/finding-planet.jpg" alt="" /></a>
                                            <h1>FINDING PLANET X-123</h1>
                                            <span>FEBRUARY 6, 2023</span>
                                            <a href="blog.html" className="more">Read More</a>
                                        </li>
                                        <li>
                                            <a href="blog.html"><img src="assets/images/new-satellitedish.jpg" alt="" /></a>
                                            <h1>NEW SATELLITE DISH</h1>
                                            <span>FEBRUARY 3, 2023</span>
                                            <a href="blog.html" className="more">Read More</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </Fragment>
    );
}

export default Index;