import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';



function Menu() {
    return (
        <Fragment>
            <div id="header">
                <div>
                    <a href="/" className="logo"><img src="assets/images/logo.png" alt="" /></a>
                    <ul id="navigation">
                        <li className="selected">
                            <Link to="/">Index</Link>
                        </li>
                        <li>
                            <Link to="/about">About</Link>
                        </li>
                        <li className="menu">
                            <Link to="/blog">Blog</Link>
                        </li>
                        <li>
                            <Link to="/contact">Contact Me</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </Fragment>
    )
}

export default Menu;