import React, { Fragment } from 'react';
import Footer from './Footer';
import Menu from './Menu';

function Contact() {


    return (
        <Fragment>
            <div id="page">
                <Menu />
                <div id="body">
                    <div className="header">
                        <div className="contact">
                            <h1>Contact</h1>
                            <h2>DO NOT HESITATE TO CONTACT US</h2>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>

        </Fragment >
    );
}

export default Contact;